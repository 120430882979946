import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { Token, VStack, Text, Caption, Flex, Ellipsis, Spacer } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'
import { PersistentExpandableCell } from './PersistentExpandableCell'
import { useFormObserver } from '../FormObserverProvider'
import { useSubmitFlowHelpers } from '../../../common/utils'
import { kpisRequests } from '@src/api/kpis'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { checkFormErrors } from './helpers'

export const TargetCell = ({
  target,
  children,
  allowDelete,
  onCopy,
  ...cellProps
}: PropsWithChildren<{
  allowDelete?: boolean
  expanded: boolean
  onToggle: () => void
  onCopy: (target?: Partial<GoalKpiDetails & { tempId?: number }>) => void
  target: GoalKpiDetails & { tempId?: number }
  index: number
}>) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { getFormById, unregisterForm } = useFormObserver()
  const { confirmationDialog, confirm, showSuccess, showError } = useSubmitFlowHelpers()

  const prettifyUnit = (unit?: string) => {
    if (unit?.includes('score')) {
      return 'score'
    }
    if (unit?.includes('tickets')) {
      return 'tickets'
    }

    return unit
  }

  const getReviewCycleName = (
    formValues: KpiInterface | undefined,
    fallback: GoalKpiDetails,
  ) => {
    const data = formValues || fallback
    return data.targets.length
      ? data.targets[0].review_cycle?.name
      : data.target_epics?.[0]?.review_cycle.name
  }

  const updateTypeDescriptionByType = {
    [UpdateTypes.manual]: 'Manual',
    [UpdateTypes.looker]: 'Looker',
    [UpdateTypes.roadmap]: 'Roadmap',
    [UpdateTypes.sql]: 'SQL',
  }

  const form = getFormById(target.id || target.tempId)?.().form

  const formValues = form?.values
  const formTarget = formValues?.targets?.[0]
  const hasFormErrors = checkFormErrors(form)
  const initialValue = formTarget?.initial_value ?? target.initial_value
  const targetValue = formTarget?.target ?? target.target
  const unit = formValues?.unit ?? target.unit
  const title = formValues?.name || target.name
  const updateType = formValues?.update_type || target.update_type
  const description =
    updateType && Object.keys(updateTypeDescriptionByType).includes(updateType)
      ? updateTypeDescriptionByType[updateType]
      : undefined

  const isDefined = (value: unknown) => typeof value !== 'undefined'
  const reviewCycle = getReviewCycleName(formValues, target)
  const isExpandedDraft = !!formValues?.tempId && cellProps.expanded
  const showPlaceholderTitle = isExpandedDraft || !title
  const placeholderTitle = (
    <Text variant="caption" color={Token.color.greyTone50}>
      New target
    </Text>
  )

  const formatNumber = (n: number) =>
    n.toLocaleString('en-GB', {
      notation: 'compact',
      compactDisplay: 'short',
    })

  const side =
    isDefined(initialValue) || isDefined(targetValue) || isDefined(reviewCycle) ? (
      <Flex gap="s-32" alignItems="center" justifyContent="flex-start">
        {isDefined(reviewCycle) ? (
          <VStack width={80}>
            <Text variant="secondary">{reviewCycle}</Text>
            <Caption color={Token.color.greyTone50}>Cycle</Caption>
          </VStack>
        ) : (
          <Spacer width={80} />
        )}
        {isDefined(initialValue) ? (
          <VStack width={80}>
            <Text variant="secondary">
              <Ellipsis tooltip="auto">
                {formatNumber(initialValue)} {prettifyUnit(unit)}
              </Ellipsis>
            </Text>
            <Caption color={Token.color.greyTone50}>Initial</Caption>
          </VStack>
        ) : (
          <Spacer width={80} />
        )}
        {isDefined(targetValue) ? (
          <VStack width={80}>
            <Text variant="secondary">
              <Ellipsis>
                {formatNumber(targetValue)} {prettifyUnit(unit)}
              </Ellipsis>
            </Text>
            <Caption color={Token.color.greyTone50}>Target</Caption>
          </VStack>
        ) : (
          <Spacer width={80} />
        )}
      </Flex>
    ) : undefined

  const handleDeleteClicked = async () => {
    if (!target.id) {
      values.kpis = values.kpis.filter(kpi => kpi.tempId !== formValues?.tempId)
      target.tempId && unregisterForm(target.tempId)
      return
    }

    const confirmed = await confirm({
      body: `Please, confirm you want to delete ${target.name} target`,
      yesMessage: 'Delete',
      yesBtnVariant: 'negative',
      noMessage: 'Cancel',
      variant: 'compact',
    })

    if (confirmed.status === 'confirmed') {
      try {
        await kpisRequests.deleteItem(target.id)
        showSuccess('Target successfully deleted')
        values.kpis = values.kpis.filter(kpi => kpi.id !== target.id)
        unregisterForm(target.id)
      } catch (err) {
        captureException(err)
        showError('Something went wrong', 'Please try again')
      }
    }
  }

  return (
    <>
      <PersistentExpandableCell
        {...cellProps}
        onCopy={() => {
          onCopy((form?.values || target) as unknown as GoalKpiDetails)
        }}
        side={isExpandedDraft ? undefined : side}
        title={showPlaceholderTitle ? placeholderTitle : title}
        description={showPlaceholderTitle ? undefined : description}
        hasFormErrors={hasFormErrors}
        onDelete={allowDelete ? handleDeleteClicked : undefined}
      >
        {children}
      </PersistentExpandableCell>
      {confirmationDialog}
    </>
  )
}
