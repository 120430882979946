import { TableWidget, Token, MoreBar, MoreBarSkeleton } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useTable } from '@src/components/Table/hooks'
import {
  GoalsStats,
  ManageGoalsPayload,
  SupportedEntityGraphPath,
  fetchEntityGoalsGraph,
  goalsListTableRequests,
} from '@src/api/goals'
import {
  OrgEntityInterface,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useEffect, useMemo, useState } from 'react'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  goalsInputWeightColumn,
  goalsNameColumn,
  goalsOwnerColumn,
  goalsProgressColumn,
  goalsWeightColumn,
  goalsApprovalStatusColumn,
  goalsChangeStatusColumn,
  goalsOrgUnitColumn,
} from '@src/constants/columns/goals'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { AddGoalAction } from './components/AddGoalAction'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkGoalApproveAction } from './components/BulkGoalApproveAction'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import LapeForm, { useLapeContext } from '../Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '../Form/Buttons/NewSaveButtonWithPopup'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import { Statuses } from '@src/interfaces'
import { GoalTargetSidebarForm } from './GoalTargetSidebarForm'
import { OverallProgress } from './components/OverallProgress'
import { GoalCycleFilter } from '@src/features/Goals/components/GoalCycleFilter'
import { rowHighlight } from '../KPI'
import { PermissionTypes } from '@src/store/auth/types'
import { useManageGoalsWeights } from './useManageGoalsWeights'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { selectPermissions } from '@src/store/auth/selectors'
import { TEMPLATE_TYPE_QUERY_KEY } from '@src/features/BulkDataImportV2/constants'

interface GoalsTableWidgetProps {
  initialFilters: FilterByInterface[]
  entity: OrgEntityInterface
  onManageModeChange?: (enabled: boolean) => void
}

export const GoalsTableWidget = ({
  initialFilters,
  entity,
  onManageModeChange,
}: GoalsTableWidgetProps) => {
  const permissions = useSelector(selectPermissions)
  const table = useTable(goalsListTableRequests, initialFilters, undefined, {
    parentIdFilterKey: 'parent_id',
  })
  const form = useLapeContext<ManageGoalsPayload>()
  const contentType = table.data[0]?.content_type?.id
  const {
    manageMode,
    handleSubmit,
    weightMode,
    toggleManageMode,
    confirmationDialog,
    autoDistributeWeights,
    validationMessage,
  } = useManageGoalsWeights({
    entity,
    contentType,
    tableData: table.data,
    cycleId: table.filterBy.find(f => f.columnName === 'cycle__id')?.filters[0]?.id,
    onUpdated: () => table.refresh(),
  })
  const [selectedTarget, setSelectedTarget] = useState<GoalKpiDetails>()

  const canManageGoals = entity.data.field_options.permissions?.includes(
    PermissionTypes.CanManageGoals,
  )
  const canSeeSettings =
    permissions.includes(PermissionTypes.ViewPerformancePreferences) &&
    (entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2)

  const isOnboarding = isOnboardingPath()

  useEffect(() => {
    form.reset({
      ...form.values,
      goals: table.data,
    })
  }, [table.data])

  useEffect(() => {
    onManageModeChange?.(manageMode)
  }, [manageMode])

  const goalsIdsToApprove = table.data
    .filter(({ approval_status }) => approval_status.id !== ApprovalStatuses.Approved)
    .map(({ id }) => ({ id }))

  const getGoalUrl = (goal: GoalsInterface) => {
    const route =
      goal.status.id === Statuses.draft
        ? ROUTES.FORMS.GOAL.EDIT
        : ROUTES.FORMS.GOAL.PREVIEW
    const onboardingRoute =
      goal.status.id === Statuses.draft
        ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
        : ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.PREVIEW

    return {
      ...getLocationDescriptor(
        pathToUrl(isOnboarding ? onboardingRoute : route, { id: goal.id }),
      ),
      search: new URLSearchParams(groupFiltersIntoURLQuery(table.filterBy)).toString(),
    }
  }

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(
    () => ({
      highlight: goal =>
        goal.status.id === Statuses.draft || goal.status.id === Statuses.archived
          ? Token.color.background_20
          : rowHighlight(goal.approval_status.id),
      cells: [
        {
          ...goalsNameColumn(getGoalUrl),
          width: 300,
        },
        entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2
          ? {
              ...goalsOrgUnitColumn({ companyName: entity.data.name }),
              width: 150,
            }
          : null,
        {
          ...(manageMode ? goalsInputWeightColumn : goalsWeightColumn),
          width: 70,
        },
        {
          ...goalsChangeStatusColumn((status, goalId) =>
            table.updateRows(
              r => r.id === goalId,
              r => ({ ...r, status }),
            ),
          ),
          width: 100,
        },
        {
          ...goalsProgressColumn,
          width: 100,
        },
        {
          ...goalsOwnerColumn,
          width: 150,
        },
        {
          ...goalsApprovalStatusColumn,
          width: 50,
        },
      ].filter(Boolean),
    }),
    [manageMode, table.data, weightMode, selectedTarget],
  )

  const entityTypePerformanceGraphPath: SupportedEntityGraphPath | undefined = {
    [EntityTypes.company]: 'company' as const,
    [EntityTypes.companyV2]: 'company' as const,
    [EntityTypes.department]: 'departments' as const,
    [EntityTypes.team]: 'teams' as const,
    [EntityTypes.teams]: 'teams' as const,
    [EntityTypes.employee]: 'employees' as const,
    [EntityTypes.employees]: 'employees' as const,
    [EntityTypes.function]: undefined,
    [EntityTypes.role]: undefined,
    [EntityTypes.specialisation]: undefined,
  }[entity.type]

  return (
    <>
      <TableWidget>
        <TableWidget.Info>
          <GoalCycleFilter
            onFilterChange={table.onFilterChange}
            columnName="cycle__id"
            selector={selectorKeys.review_cycles}
            filter={table.filterBy}
          />
          <OverallProgress
            value={table.stats?.overall_progress}
            fetchData={
              entityTypePerformanceGraphPath
                ? fetchEntityGoalsGraph(entityTypePerformanceGraphPath)
                : undefined
            }
            id={entity?.data.id}
          />
        </TableWidget.Info>
        <TableWidget.Actions>
          {table.loading ? (
            <MoreBarSkeleton />
          ) : (
            <MoreBar>
              <BulkGoalApproveAction
                goals={goalsIdsToApprove}
                onApproved={table.refresh}
                title={
                  goalsIdsToApprove.length === table.data.length
                    ? 'Approve all goals'
                    : undefined
                }
              />
              <AddGoalAction />
              {canSeeSettings && (
                <MoreBar.Action
                  useIcon="Upload"
                  onClick={() =>
                    navigateTo(
                      pathToUrl(
                        isOnboarding
                          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.UPLOAD.IMPORT
                          : ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT,
                        undefined,
                        { [TEMPLATE_TYPE_QUERY_KEY]: 'dynamic' },
                      ),
                    )
                  }
                >
                  Import goals
                </MoreBar.Action>
              )}
              {table.data.length > 0 && canManageGoals && (
                <MoreBar.Action
                  useIcon={manageMode ? 'SwitchOn' : 'SwitchOff'}
                  onClick={toggleManageMode}
                >
                  Edit weights
                </MoreBar.Action>
              )}
              {manageMode && (
                <MoreBar.Action useIcon="AutoExchange" onClick={autoDistributeWeights}>
                  Auto-distribute weights
                </MoreBar.Action>
              )}
              {canSeeSettings && (
                <MoreBar.Action
                  to={pathToUrl(
                    isOnboarding
                      ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.ALL
                      : ROUTES.SETTINGS.GOALS.ALL,
                  )}
                  use={InternalLink}
                  useIcon="Gear"
                >
                  Settings
                </MoreBar.Action>
              )}
            </MoreBar>
          )}
        </TableWidget.Actions>
        <TableWidget.Table>
          {manageMode ? (
            <LapeEditableTable<GoalsInterface>
              dataFieldName="goals"
              name={TableNames.Goals}
              dataType="Goal"
              disableFilters={manageMode}
              {...table}
              initialData={table.data}
              row={row}
              replaceOnInitialDataChange
            />
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              dataType="Goal"
              {...table}
              row={row as RowInterface<GoalsInterface>}
              expandableType="chevron"
              useFetchedChildren
            />
          )}

          {manageMode && !table.loading && table.data.length ? validationMessage : null}
        </TableWidget.Table>
      </TableWidget>
      {manageMode && form.dirty && (
        <PageActions my="s-32" maxWidth="100%">
          <NewSaveButtonWithPopup
            disabled={!!validationMessage}
            onClick={() => handleSubmit()}
            successText="Goal weights have been updated"
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
      <GoalTargetSidebarForm
        target={selectedTarget}
        onClose={() => setSelectedTarget(undefined)}
        onAfterSubmit={() => {
          table.refresh()
          setSelectedTarget(undefined)
        }}
      />
      {confirmationDialog}
    </>
  )
}

export const GoalsTableFormWidget = ({
  initialFilters,
  onManageModeChange,
}: {
  initialFilters: FilterByInterface[]
  onManageModeChange?: (enabled: boolean) => void
}) => {
  const { entity } = useOrgEntity()

  const initialValues = useMemo(() => {
    return entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode
      ? {
          goals: [],
          goal_weight_mode: entity.data.goal_weight_mode,
        }
      : {
          goals: [],
          goal_weight_mode: { id: 'automatic' },
        }
  }, [entity])

  return entity ? (
    <LapeForm
      disableValidation
      onSubmit={() => Promise.resolve({})}
      initialValues={initialValues}
    >
      <GoalsTableWidget
        initialFilters={initialFilters}
        entity={entity}
        onManageModeChange={onManageModeChange}
      />
    </LapeForm>
  ) : null
}
