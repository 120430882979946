import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'

import { useQuery } from '@src/utils/queryParamsHooks'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { IdAndName } from '@src/interfaces'
import {
  BulkDeleteButton,
  BulkEditAction,
} from '@src/features/GenericEditableTable/components'
import { CreateTeamPopup } from '@src/features/GenericEditableTable/CellPopups/CreateTeam'
import { CreateRolePopup } from '@src/features/GenericEditableTable/CellPopups/CreateRole'
import { CreateEntityPopup } from '@src/features/GenericEditableTable/CellPopups/CreateEntity'
import { CreateDepartmentPopup } from '@src/features/GenericEditableTable/CellPopups/CreateDepartment'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CreateLocationPopup } from '@src/features/GenericEditableTable/CellPopups/CreateLocation'
import {
  DYNAMIC_COLUMNS_QUERY_KEY,
  CUSTOM_FIELDS_QUERY_KEY,
} from '@src/features/GenericEditableTable/constants'
import { TEMPLATE_TYPE_QUERY_KEY } from '@src/features/BulkDataImportV2/constants'
import { IS_EMPLOYEE_UPDATE_QUERY_KEY } from './constants'
import { BulkEmployeeUploadFlowProps, CreateNewItemType } from '.'
import { useGetSectionCustomFields } from '@src/api/customFields'
import { SectionOptions } from '@src/interfaces/customFields'

export const BulkEmployeeUploadFlow = ({
  row,
  hiddenColumns,
  getHeader,
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  onAfterConfirmRoute,
  renderMoreActions,
  headerTitle,
  visibleActions,
}: BulkEmployeeUploadFlowProps) => {
  const { query } = useQuery()

  const [createNewTypeState, setCreateNewTypeState] = useState<{
    type: CreateNewItemType
    employeeId: number
    onChangeAction: (entity: IdAndName) => void
  }>()

  const onCreateNew = (
    type: CreateNewItemType,
    employeeId: number,
    onChangeAction: (entity: IdAndName) => void,
  ) => {
    setCreateNewTypeState({ type, employeeId, onChangeAction })
  }

  const customFieldsIds = query[CUSTOM_FIELDS_QUERY_KEY] || ''
  const { data: customFieldsData } = useGetSectionCustomFields(
    customFieldsIds ? SectionOptions.EmployeeProfile : undefined,
    [],
    customFieldsIds.split(','),
  )
  const customFields = customFieldsData?.results || []

  return (
    <>
      <BulkDataUploadV2
        importRoute={importRoute}
        sessionRoute={sessionRoute}
        anyRoute={anyRoute}
        apiEndpoint={apiEndpoint}
        category="employee_upload"
        name="employee"
        tableName={TableNames.ImportEmployeesV2}
        row={row(onCreateNew, 'bulkFlow', customFields)}
        entity="employee"
        templateParams={{
          version: '2',
          ...(query.updateEmployees
            ? null
            : { extra_columns: 'access_level,seniority,team,specialisation' }),
        }}
        header={getHeader(headerTitle)}
        hiddenColumns={hiddenColumns}
        onAfterConfirmRoute={onAfterConfirmRoute}
        ignoreQueryParams={[
          DYNAMIC_COLUMNS_QUERY_KEY,
          CUSTOM_FIELDS_QUERY_KEY,
          TEMPLATE_TYPE_QUERY_KEY,
          IS_EMPLOYEE_UPDATE_QUERY_KEY,
        ]}
        tableActions={tableActionsProps => (
          <MoreBar>
            {visibleActions.team && (
              <BulkEditAction
                buttonIcon="Suitcase"
                field="team"
                selector={selectorKeys.team}
                {...tableActionsProps}
              />
            )}
            {visibleActions.specialisation && (
              <BulkEditAction
                buttonIcon="RepairTool"
                field="specialisation"
                selector={selectorKeys.specialisations}
                {...tableActionsProps}
                label="role"
              />
            )}
            {visibleActions.seniority && (
              <BulkEditAction
                buttonIcon="ArrowRightLeft"
                field="seniority"
                selector={selectorKeys.seniority}
                {...tableActionsProps}
              />
            )}

            {renderMoreActions?.(tableActionsProps)}
            <BulkDeleteButton {...tableActionsProps} />
          </MoreBar>
        )}
      />

      <CreateTeamPopup
        open={createNewTypeState?.type === 'team'}
        onSuccess={team => {
          createNewTypeState?.onChangeAction(team)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateRolePopup
        defaultToMaxSeniorityRange
        open={createNewTypeState?.type === 'role'}
        onSuccess={role => {
          createNewTypeState?.onChangeAction(role)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateEntityPopup
        open={createNewTypeState?.type === 'companyEntity'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />

      <CreateLocationPopup
        open={createNewTypeState?.type === 'location'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
        showSuccessPopup={false}
      />

      <CreateDepartmentPopup
        open={createNewTypeState?.type === 'department'}
        onSuccess={entity => {
          createNewTypeState?.onChangeAction(entity)
          setCreateNewTypeState(undefined)
        }}
        onClose={() => setCreateNewTypeState(undefined)}
      />
    </>
  )
}
