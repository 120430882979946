import React, { useState } from 'react'
import pluralize from 'pluralize'
import {
  Bullet,
  Button,
  Flex,
  HStack,
  IconButton,
  Item,
  Spinner,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import {
  useTemplateFileDownload,
  useTemplateFileUpload,
} from '@src/utils/useBulkUploadTemplate'
import {
  BulkUploadConfigurableField,
  ImportDataCategory,
} from '@src/interfaces/bulkDataImport'
import { useQuery } from '@src/utils/queryParamsHooks'
import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import { AddFieldsSidebar } from './AddFieldsSidebar'
import {
  getAddedFieldsAfterDeletion,
  getTemplateDownloadConfigurableFieldsParams,
  getUploadParamsByConfigurableFields,
} from './helpers'
import { TEMPLATE_TYPE_QUERY_KEY } from '@src/features/BulkDataImportV2/constants'

type ListItemProps = {
  idx: number
  text: string | React.ReactNode
}
const ListItem: React.FC<ListItemProps> = ({ idx, text, children }) => {
  return (
    <HStack space="s-16">
      <Bullet variant="filled">{idx}</Bullet>
      <VStack space="s-8" width="100%">
        <Text fontSize="16px">{text}</Text>
        {children}
      </VStack>
    </HStack>
  )
}

export interface BulkDataImportUploadFileV2Props {
  category: ImportDataCategory
  apiEndpoint: string
  name: string
  nextRoute: string
  templateParams?: Record<string, string>
  uploadParams?: Record<string, string | boolean>
}
export const BulkDataImportUploadFileV2 = ({
  category,
  apiEndpoint,
  name,
  nextRoute,
  templateParams,
  uploadParams,
}: BulkDataImportUploadFileV2Props) => {
  const { query } = useQuery()

  const [isFieldsSidebarOpen, setIsFieldsSidebarOpen] = useState(false)
  const [configurableFields, setConfigurableFields] = useState<
    BulkUploadConfigurableField[]
  >([])

  const mandatoryColumns = templateParams?.extra_columns?.split(',') || []
  const configurableFieldsParams = getTemplateDownloadConfigurableFieldsParams(
    mandatoryColumns,
    configurableFields,
  )
  const { onDownloadTemplate, isDownloadPending } = useTemplateFileDownload({
    name,
    endpoint: apiEndpoint,
    params: {
      ...templateParams,
      ...configurableFieldsParams,
    },
  })

  const { onUploadTemplate, isUploadPending, uploadTemplateError } =
    useTemplateFileUpload({
      category,
      endpoint: apiEndpoint,
      navigateOnSuccessPath: nextRoute,
      params: {
        ...uploadParams,
        ...getUploadParamsByConfigurableFields(configurableFields),
      },
    })

  const canConfigureFields = query[TEMPLATE_TYPE_QUERY_KEY] === 'dynamic'

  const listItems = [
    canConfigureFields
      ? {
          text: 'Select the fields you want to add',
          children: (
            <VStack>
              {configurableFields.map(field => (
                <Item key={field.name}>
                  <Item.Content>{field.name}</Item.Content>
                  <Item.Side>
                    <IconButton
                      useIcon="Delete"
                      color={Token.color.greyTone20}
                      onClick={() =>
                        setConfigurableFields(
                          getAddedFieldsAfterDeletion(name, field, configurableFields),
                        )
                      }
                      aria-label={`Remove ${field.name}`}
                    />
                  </Item.Side>
                </Item>
              ))}
              <Button
                size="sm"
                useIcon="Plus"
                onClick={() => setIsFieldsSidebarOpen(true)}
                mt={configurableFields.length ? 's-8' : undefined}
              >
                Add field
              </Button>
            </VStack>
          ),
        }
      : null,
    {
      text: <>Use our {pluralize(name)} data template</>,
      children: (
        <Button
          onClick={onDownloadTemplate}
          pending={isDownloadPending}
          size="sm"
          useIcon="Download"
        >
          Download template
        </Button>
      ),
    },
    {
      text: (
        <>
          <b>Fill in the columns</b>, leverage the provided inputs when possible
        </>
      ),
    },
    {
      text: (
        <>
          <b>Upload your file</b> by “drag & drop or Click to attach document”
        </>
      ),
    },
  ].filter(Boolean)

  return (
    <Widget p="s-24">
      <VStack space="s-20">
        {listItems.map(({ text, children }, idx) => (
          <ListItem key={idx} idx={idx + 1} text={text}>
            {children}
          </ListItem>
        ))}

        {isUploadPending ? (
          <Flex justifyContent="center">
            <Spinner />
          </Flex>
        ) : (
          <FileUploader
            label={`CSV/XLSX attachment`}
            onChange={onUploadTemplate}
            hideOptional
            name="file"
            error={uploadTemplateError}
            showDropAreaImage
            noDropAreaHighlight
            attachButtonText="click to upload file"
          />
        )}
      </VStack>
      {canConfigureFields && (
        <AddFieldsSidebar
          isOpen={isFieldsSidebarOpen}
          onClose={() => setIsFieldsSidebarOpen(false)}
          entityName={name}
          onAddField={newField =>
            setConfigurableFields([...configurableFields, newField])
          }
          onRemoveField={field =>
            setConfigurableFields(
              getAddedFieldsAfterDeletion(name, field, configurableFields),
            )
          }
          addedFields={configurableFields}
          mandatoryColumns={mandatoryColumns}
        />
      )}
    </Widget>
  )
}
