import React from 'react'
import { Group, VStack, Text, Token, Box } from '@revolut/ui-kit'

import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectorKeys } from '@src/constants/api'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface TargetTypeFieldsProps {
  disableEdit: boolean
}

export const TargetTypeFields = ({ disableEdit }: TargetTypeFieldsProps) => {
  const featureFlags = useSelector(selectFeatureFlags)

  return (
    <VStack space="s-24">
      <Group>
        <VStack space="s-8" p="s-16">
          <Text variant="primary">KPI types</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            What types of KPIs are allowed
          </Text>
        </VStack>

        <Box pb="s-8" px="s-12">
          <LapeNewMultiSelect
            name="allowed_kpi_types"
            placeholder="Allowed KPI types"
            selector={selectorKeys.allowed_kpi_categories}
            message="This will restrict what kind of KPIs can be added in the system."
            required
            disabled={disableEdit}
          />
        </Box>
        <Box pb="s-8" px="s-12">
          <LapeNewMultiSelect
            name="allowed_kpi_strategies"
            placeholder="Allowed KPI strategies"
            selector={selectorKeys.allowed_kpi_strategies}
            message="This will restrict the strategies possible for measuring the progress of a KPI."
            required
            disabled={disableEdit}
          />
        </Box>
      </Group>
      <LapeMultiInput
        title="Measuring units"
        name="kpi_measuring_units"
        valueFieldName={undefined}
        description="What unit will the KPI progress be measured in. Ex: percent (%), dollars ($), etc."
        label="E.g. percent (%)"
        withCell
        disabled={disableEdit}
      />
      <Group>
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Allow Function and Role KPIs',
            description: 'Allow to set KPIs for functional level units',
          }}
          name="enable_function_role_specialisation_kpis"
          disabled={disableEdit}
        />

        <LapeNewSwitch
          itemTypeProps={{
            title: 'Enforce the parent KPI field as mandatory',
            description:
              'Enabling this will require all created KPIs to be connected to a higher level parent KPI (e.g. a department KPI set as the parent for a team KPI).',
          }}
          name="enable_mandatory_parent_kpi"
        />
        {featureFlags.includes(FeatureFlags.CanAddGoals) && (
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Allow multiple metrics per review cycle to be added to a goal',
            }}
            name="enable_multiple_goal_targets_per_cycle"
          />
        )}
      </Group>
    </VStack>
  )
}
